import { Auth0Client, Auth0ClientOptions } from "@auth0/auth0-spa-js";

import AuthAdapterInterface from "./AuthAdapterInterface";
import { sendTrackingData } from "../../utils/tracking";

class AuthzeroAdapter implements AuthAdapterInterface {
  private readonly client: Auth0Client;

  constructor(options: Auth0ClientOptions) {
    this.client = new Auth0Client(options);
  }

  async onInit() {
    await this.client.checkSession();
  }

  async isAuthenticated() {
    return await this.client.isAuthenticated();
  }

  async getAccessToken() {
    return await this.client.getTokenSilently();
  }

  async getIdToken() {
    const data = await this.client.getTokenSilently({
      detailedResponse: true,
    });

    return data.id_token;
  }

  async loginWithRedirect(returnTo?: string) {
    return await this.client.loginWithRedirect({
      appState: {
        returnTo: returnTo || window.location.pathname + window.location.search,
      },
    });
  }

  async signupWithRedirect(returnTo?: string) {
    return await this.client.loginWithRedirect({
      screen_hint: "signup",
      appState: {
        returnTo: returnTo || window.location.pathname + window.location.search,
      },
    });
  }

  async handleRedirectCallback(onRedirect?: (returnTo: string) => void) {
    if (this.hasRedirectParams()) {
      try {
        const { appState } = await this.client.handleRedirectCallback<{
          returnTo: string;
        }>();
        const returnTo = appState?.returnTo || window.location.pathname;

        // Attempt to track the user's login
        try {
          sendTrackingData("logged_in");
        } catch (trackErr: unknown) {
          console.error("Error tracking login", trackErr);
        }

        if (onRedirect) {
          onRedirect(returnTo);
          return;
        }

        window.history.replaceState({}, document.title, returnTo);
      } catch (error: unknown) {
        if (error instanceof Error) {
          if (error.message === "Invalid state") {
            await this.client.logout();
          }
        }
      }
    }
  }

  async getUser() {
    return await this.client.getUser();
  }

  async logout() {
    return await this.client.logout({ returnTo: window.location.origin });
  }

  private hasRedirectParams() {
    const searchParams = new URLSearchParams(window.location.search);
    return (
      (searchParams.has("code") || searchParams.has("error")) &&
      searchParams.has("state") &&
      !(
        window.location.pathname.match(/accept-thinkific/) ||
        window.location.pathname.match(/start-thinkific/) ||
        window.location.pathname.match(/accept-code/)
      )
    );
  }
}

export default AuthzeroAdapter;
