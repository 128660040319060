import apiCall, { makeUrl } from "../../utils/apiCall";
import { delay, put } from "redux-saga/effects";
import querystring from "querystring";

export function* addListeners({ feedId, emails, startDate, content, sendEmail, callback }) {
  const key = "add-listeners";
  yield put({ type: "SET_LOADING", key, loading: true });
  const postListenersCall = yield apiCall();
  yield postListenersCall.post(makeUrl("/listeners/mass"), {
    content,
    emails: emails.split(/\n|\r|,/),
    feedId,
    listenerIds: [],
    sendEmail,
    startDate,
    status: "active",
  });
  yield put({ type: "SET_LOADING", key, loading: false });
  yield callback();
  yield put({ type: "GET_FEED", feedId, silent: true });
  yield getListeners({ feedId, silent: true });
}

export function* setSuperlistenerOptIn({ callback, listenerId, smsOptIn }) {
  const key = `sms-opt-in-${listenerId}`;
  yield put({ type: "SET_LOADING", key, loading: true });
  const updateListenerCall = yield apiCall();
  yield updateListenerCall.patch(makeUrl("/listeners/sms-opt-in"), {
    listenerId,
    smsOptIn,
  });
  yield put({ type: "SET_LOADING", key, loading: false });
  if (typeof callback === "function") {
    yield callback();
  }
}

export function* getListeners({
  emailFilter = "",
  limit = 100,
  feedId,
  newFetch,
  page = 0,
  silent = false,
  sortDirection = "desc",
  sortField = "start_date",
  statusFilter = "",
}) {
  if (!silent) {
    yield put({ type: "SET_LOADING", key: "get-listeners", loading: true });
  }

  if (newFetch) {
    yield put({
      type: "SET_LOADING",
      key: "get-more-listeners",
      loading: true,
    });
  }
  const getListenerCall = yield apiCall();
  const listenerResults = yield getListenerCall.get(
    makeUrl("/listeners?") +
      querystring.encode({
        feedId,
        limit,
        page,
        emailFilter,
        sortField,
        sortDirection,
        statusFilter,
      }),
  );

  console.log({
    feedId,
    limit,
    page,
    emailFilter,
    sortField,
    sortDirection,
    statusFilter,
  });
  console.log("page:", page);
  console.log(listenerResults.data);
  yield put({
    type: "SAVE_LISTENERS",
    listeners: listenerResults.data.listeners,
    page,
    totalPages: listenerResults.data.totalPages,
  });

  // Delay to prevent race condition between loader and listeners display
  yield delay(100);
  yield put({ type: "SET_LOADING", key: "get-more-listeners", loading: false });
  yield put({ type: "SET_LOADING", key: "get-listeners", loading: false });
}

export function* getSingleListener({ feedId, listenerId }) {
  const getListenerCall = yield apiCall();
  const listenerResults = yield getListenerCall.get(
    makeUrl("/single-listener?") + querystring.encode({ feedId, listenerId }),
  );
  yield put({
    type: "SAVE_SINGLE_LISTENER",
    listener: listenerResults.data.listener,
  });
  yield put({
    type: "SET_LOADING",
    key: `edit-listeners-${listenerId}`,
    loading: false,
  });
}

export function* getListenerCount({ feedId }) {
  const getListenerCountCall = yield apiCall();
  const countResults = yield getListenerCountCall.get(
    makeUrl("/listener-count?") + querystring.encode({ feedId }),
  );
  const listenerCount = countResults.data.listenerCount;
  yield put({ type: "SAVE_FEED_LISTENER_COUNT", listenerCount });
}

export function* editListeners({
  callback,
  expirationDate,
  feedId,
  listenerIds,
  resend,
  startDate,
  status,
  subscribePage,
}) {
  if (listenerIds.length > 1) {
    yield put({ type: "SET_LOADING", key: "edit-listeners", loading: true });
  } else {
    yield put({
      type: "SET_LOADING",
      key: `edit-listeners-${listenerIds}`,
      loading: true,
    });
  }
  const postListenersCall = yield apiCall();
  yield postListenersCall.post(makeUrl("/listeners"), {
    emails: [],
    expirationDate,
    feedId,
    listenerIds,
    resend,
    startDate,
    status,
    subscribePage,
  });
  if (listenerIds.length > 1) {
    yield put({ type: "SET_LOADING", key: "edit-listeners", loading: false });
  } else {
    yield getSingleListener({ listenerId: listenerIds[0], feedId });
  }
  yield callback();
}

export function* deleteListener({ listenerId, callback }) {
  const key = `delete-listener-${listenerId}`;
  yield put({ type: "SET_LOADING", key, loading: true });
  const deleteListenerCall = yield apiCall();
  yield deleteListenerCall.post(makeUrl("/listeners/delete"), {
    listenerId,
  });
  yield put({ type: "SET_LOADING", key, loading: false });
  yield put({ type: "SET_SUCCESS", success: "Listener has been deleted" });
  callback();
}
